// App.js

import React, { useState } from 'react';
import './App.css';
import Header from './Header';
import ScrollableBox from './ScrollableBox';

function App() {

  // State to manage the switch toggle
  const [isChecked, setIsChecked] = useState(false);

  const handleSwitchToggle = () => {
    setIsChecked(!isChecked);
  };

  // Sample Reddit posts data
  const redditPosts = [
    {
      id: 1,
      title: 'Lorem ipsum dolor sit amet',
      content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      author: 'user123',
      timestamp: '2023-12-05',
      subreddit: 'reactjs',
    },
    {
      id: 2,
      title: 'Sed ut perspiciatis unde omnis',
      content: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.',
      author: 'redditUser456',
      timestamp: '2023-12-04',
      subreddit: 'javascript',
    },
    {
      id: 3,
      title: 'Consectetur adipiscing elit',
      content: 'Vestibulum venenatis dolor in velit laoreet, in luctus est facilisis. Proin vulputate nisl nec lectus eleifend, nec commodo elit lacinia.',
      author: 'user789',
      timestamp: '2023-12-03',
      subreddit: 'webdev',
    },
    {
      id: 4,
      title: 'Vestibulum venenatis dolor',
      content: 'Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nulla facilisi.',
      author: 'redditUser101',
      timestamp: '2023-12-02',
      subreddit: 'frontend',
    },
    {
      id: 5,
      title: 'Nulla facilisi',
      content: 'Sed tincidunt velit vel lorem sodales, sit amet blandit ex efficitur. Mauris vulputate nisi at justo eleifend, non tincidunt risus pellentesque.',
      author: 'userXYZ',
      timestamp: '2023-12-01',
      subreddit: 'reactnative',
    },
    // Add more Reddit posts as needed
  ];

  return (
    <div className="App">
      <Header />
      <div className="content">
      <div className="search-box">
          <input
            type="text"
            placeholder="Search..."
            className="search-input"
          />
          <label className="switch">
            <input
              type="checkbox"
              checked={isChecked}
              onChange={handleSwitchToggle}
            />
            <span className={`slider ${isChecked ? 'checked' : ''}`}>
              {isChecked ? 'Using AI' : 'Use AI'}
            </span>
          </label>
        </div>
        <div className="side-section">
          <div className="current-searches">
            <h3>Current Searches</h3>
            <div className="current-searches-list">
              <ul>
                <li>r/smallbusiness</li>
                <li>r/javascript</li>
                <li>r/webdev</li>
                <li>r/devshop</li>
                <li>r/devs</li>
                <li>r/websites</li>
                <li>r/web_design</li>
              </ul>
            </div>
          </div>
          <div className="scrollable-box-container">
            <button className="new-posts-button">New Posts & Comments</button>
            <ScrollableBox posts={redditPosts} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;