// Header.js

import React from 'react';
import './Header.css'; // Import your header CSS
import myLogo from './logo.svg'; // Import your logo image

const Header = () => {
  return (
    <header className="header">
      <div className="logo-container">
        <img src={myLogo} alt="My Logo" /> {/* Replace with your logo */}
        <span className="logo-text">Shadow</span> {/* Use a span for the text */}
      </div>
      <nav className="navigation">
        <ul>
          <li><a href="#home">Home</a></li>
          <li><a href="#about">About</a></li>
          <li><a href="#services">Services</a></li>
          <li><a href="#contact">Contact</a></li>
          <li><button className="signup-btn">Sign Up</button></li>
          <li><button className="signin-btn">Sign In</button></li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;