// ScrollableBox.js

import React from 'react';
import RedditPost from './RedditPost'; // Import the RedditPost component
import './ScrollableBox.css'; // Import the ScrollableBox component CSS

const ScrollableBox = ({ posts }) => {
  return (
    <div className="scrollable-box">
      {posts.map((post) => (
        <RedditPost
          key={post.id}
          title={post.title}
          content={post.content}
          author={post.author}
          timestamp={post.timestamp}
          subreddit={post.subreddit}
        />
      ))}
    </div>
  );
};

export default ScrollableBox;
