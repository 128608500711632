// RedditPosts.js
import React from 'react';

import './RedditPost.css'; // Import the associated CSS file
const RedditPost = ({ title, content, author, timestamp, subreddit }) => {
  return (
    <div className="reddit-post">
      <div className="post-header">
        <p className="post-author">Posted by {author} {timestamp}</p>
        <p className="post-subreddit">r/{subreddit}</p>
      </div>
      <h3 className="post-title">{title}</h3>
      <p className="post-content">{content}</p>
      <div className="post-details">
        <p className="post-timestamp">{timestamp}</p>
      </div>
    </div>
  );
};
export default RedditPost;